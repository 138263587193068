import { Decodable } from './_decodable';
import { Director } from './director';

class CriticalAnalysisLog extends Decodable {

    id: number
    action: number
    director: Director
    observation: string
    actions = {
        opened: 1,
        approved: 2,
        reproved: 3,
        sent_automatically: 4,
        sent_manually: 5,
        finalize: 6,
    };

    actionsMapping = {
        [this.actions.opened]: 'abriu o link da análise crítica',
        [this.actions.approved]: 'aprovou a análise crítica',
        [this.actions.reproved]: 'reprovou a análise crítica',
        [this.actions.sent_automatically]: 'recebeu o link da análise crítica a partir do envio automático',
        [this.actions.sent_manually]: 'recebeu o link da análise crítica a partir do envio manual',
        [this.actions.finalize]: 'encerrou o processo da análise crítica automaticamente',
    };

    getActionClass(action: number): string {
        if (action === this.actions.opened || action === this.actions.sent_automatically || action === this.actions.sent_manually || action === this.actions.finalize) {
            return 'action-blue';
        } else if (action === this.actions.approved) {
            return 'action-green';
        } else if (action === this.actions.reproved) {
            return 'action-red';
        }

        return '';
    }

    getActionIcon(action: number): string {
        if (action === this.actions.opened || action === this.actions.sent_automatically || action === this.actions.sent_manually || action === this.actions.finalize) {
            return 'star';
        } else if (action === this.actions.approved) {
            return 'check_circle';
        } else if (action === this.actions.reproved) {
            return 'cancel';
        }

        return '';
    }

    updatedAt: Date
    createdAt: Date


    protected _map = {
        updatedAt: e => this.parseDate(e),
        createdAt: e => this.parseDate(e),
        directors: e => Director.parse(e),
        history: e => Director.parse(e),
    }
}

export {
    CriticalAnalysisLog
}