import { PedClientsModule } from './main/pages/ped-clients/ped-clients.module';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, Routes, RouteReuseStrategy } from '@angular/router';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import localePt from '@angular/common/locales/pt';

import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule, FuseConfirmDialogModule, FuseMaterialColorPickerModule } from '@fuse/components';

import { fuseConfig, echoConfig } from 'app/config';

import { NgxLaravelEchoModule } from 'ngx-laravel-echo';


import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { AuthService } from './services/auth.service';
import { registerLocaleData } from '@angular/common';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { EnvServiceProvider } from './services/env.service.factory';
import { EnvService } from './services/env.service';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { RoleGuard } from './role.guard';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'planos-producao',
        pathMatch: 'full',
        canActivate: [RoleGuard],
        data: {
            allowedRoles: ['admin']
        }
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./main/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [RoleGuard],
        data: {
            allowedRoles: ['admin']
        }
    },
    {
        path: 'dashboard-producao',
        loadChildren: () => import('./main/pages/dashboard-producao/dashboard-producao.module').then(m => m.DashboardProducaoModule),
        canActivate: [RoleGuard],
        data: {
            allowedRoles: ['admin']
          }
    },
    {
        path: 'mapa-clientes',
        loadChildren: () => import('./main/pages/dashboard-mapa-clientes/dashboard-mapa-clientes.module').then(m => m.DashboardMapaClientesModule),
        canActivate: [RoleGuard],
        data: {
            allowedRoles: ['admin', 'supplies']
          }
    },
    {
        path: 'produtos-formulados',
        loadChildren: () => import('./main/pages/formulated-products/formulated-products.module').then(m => m.FormulatedProductsModule),
        canActivate: [RoleGuard],
        data: {
            allowedRoles: ['admin']
        }
    },
    {
        path: 'dashboard-amostras',
        loadChildren: () => import('./main/pages/dashboard-amostras/dashboard-amostras.module').then(m => m.DashboardAmostrasModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin']
        }
      },
      {
        path: 'auth',
        loadChildren: () => import('./main/pages/auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: 'planos-producao',
        loadChildren: () => import('./main/pages/clients/clients.module').then(m => m.ClientsModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin']
        }
      },
      {
        path: 'clientes',
        loadChildren: () => import('./main/pages/ped-clients/ped-clients.module').then(m => m.PedClientsModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin']
        }
      },
      {
        path: 'users',
        loadChildren: () => import('./main/pages/users/users.module').then(m => m.UsersModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin']
        }
      },
      {
        path: 'equipament',
        loadChildren: () => import('./main/pages/equipamentos/equipamentos.module').then(m => m.EquipamentosModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin', 'supplies', 'shipping']
        }
      },
      {
        path: 'types',
        loadChildren: () => import('./main/pages/tipos/tipos.module').then(m => m.TiposModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin']
        }
      },
      {
        path: 'categories',
        loadChildren: () => import('./main/pages/categorias/categorias.module').then(m => m.CategoryModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin']
        }
      },
      {
        path: 'encarregados',
        loadChildren: () => import('./main/pages/encarregados/encarregado.module').then(m => m.EncarregadosModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin', 'hr', 'shipping']
        }
      },
      {
        path: 'operadores',
        loadChildren: () => import('./main/pages/machine-operator/machine-operator.module').then(m => m.MachineOperatorModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin', 'hr', 'shipping']
        }
      },
      {
        path: 'motoristas',
        loadChildren: () => import('./main/pages/driver/driver.module').then(m => m.DriverModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin', 'hr', 'shipping']
        }
      },
      {
        path: 'auxiliares',
        loadChildren: () => import('./main/pages/assistant/assistant.module').then(m => m.AssistantModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin', 'hr', 'shipping']
        }
      },
      {
        path: 'allocations',
        loadChildren: () => import('./main/pages/allocation/allocations.module').then(m => m.AllocationModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin', 'supplies']
        }
      },
      {
        path: 'products',
        loadChildren: () => import('./main/pages/products/products.module').then(m => m.ProductsModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin']
        }
      },
      {
        path: 'cycles',
        loadChildren: () => import('./main/pages/cycles/cycles.module').then(m => m.CyclesModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin']
        }
      },
      {
        path: 'organic-materials',
        loadChildren: () => import('./main/pages/organic-materials/organic-materials.module').then(m => m.OrganicMaterialsModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin']
        }
      },
      {
        path: 'stone-delivery',
        loadChildren: () => import('./main/pages/stone-deliverys/stone-deliverys.module').then(m => m.StoneDeliveryModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin']
        }
      },
      {
        path: 'accompaniments',
        loadChildren: () => import('./main/pages/accompaniments/accompaniments.module').then(m => m.AccompanimentsModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin']
        }
      },
      {
        path: 'suppliers',
        loadChildren: () => import('./main/pages/stone-suppliers/stone-supplier.module').then(m => m.StoneSupplierModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin']
        }
      },
      {
        path: 'prospectos',
        loadChildren: () => import('./main/pages/prospectos/prospectos.module').then(m => m.ProspectosModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin']
        }
      },
      {
        path: 'veiculos',
        loadChildren: () => import('./main/pages/vehicles/vehicles.module').then(m => m.VehiclesModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin']
        }
      },
      {
        path: 'equipament-lessors',
        loadChildren: () => import('./main/pages/equipement-lessors/equipement-lessors.module').then(m => m.EquipamentLessorModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin']
        }
      },
      {
        path: 'motivos',
        loadChildren: () => import('./main/pages/reason/reason.module').then(m => m.ReasonModule),
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['admin']
        }
      },
      {
        path: 'dashboard-rh',
        loadChildren: () => import('./main/pages/dashboard-rh/dashboard-rh.module').then(m => m.DashboardRhModule),
        canActivate: [RoleGuard],
        data: {
            allowedRoles: ['admin', 'hr', 'shipping']
          }
      },
      {
        path: 'comercial',
        loadChildren: () => import('./main/pages/boards/comercial/comercial.module').then(m => m.ComercialModule),
        canActivate: [RoleGuard],
        data: {
            allowedRoles: ['admin']
          }
      },
      {
        path: 'research',
        loadChildren: () => import('./main/pages/boards/research/research.module').then(m => m.ResearchModule),
        canActivate: [RoleGuard],
        data: {
            allowedRoles: ['admin']
          }
      },
      {
        path: 'administrative',
        loadChildren: () => import('./main/pages/boards/administrative/administrative.module').then(m => m.AdministrativeModule),
        canActivate: [RoleGuard],
        data: {
            allowedRoles: ['admin']
          }
      },
      {
        path: 'executive',
        loadChildren: () => import('./main/pages/boards/executive/executive.module').then(m => m.ExecutiveModule),
        canActivate: [RoleGuard],
        data: {
            allowedRoles: ['admin']
          }
      },
];

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM/YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MMM/YYYY',
    },
};

registerLocaleData(localePt);

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { useHash: true}),

        NgxLaravelEchoModule.forRoot(echoConfig),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        MatDialogModule,
        MatDatepickerModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        FuseConfirmDialogModule,
        FuseMaterialColorPickerModule,

        // App modules
        LayoutModule,
    ],
    providers: [
        EnvServiceProvider,
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }, //you can change useValue
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
    constructor(_authService: AuthService, envService: EnvService) {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token && token.length == 64) {
            localStorage.setItem('currentUserToken', token);
        }

        _authService.populate();
    }
}