import { Decodable } from './_decodable';
import { Director } from './director';

class Board extends Decodable {

    id: number
    name: string
    directors: Director[]
    history: Director[]

    updatedAt: Date
    createdAt: Date


    protected _map = {
        updatedAt: e => this.parseDate(e),
        createdAt: e => this.parseDate(e),
        directors: e => Director.parse(e),
        history: e => Director.parse(e),
    }
}

export {
    Board
}