import { Decodable } from './_decodable';
import { Board } from './board';

class Director extends Decodable {

    id: number
    name: string
    email: string
    file: string
    boards: Board[]
    pivot: {
      attached_at: string;
      detached_at: string | null;
    };

    updatedAt: Date
    createdAt: Date


    protected _map = {
        updatedAt: e => this.parseDate(e),
        createdAt: e => this.parseDate(e),
        boards: e => Board.parse(e),
    }
}

export {
    Director
}